.search-page-layout-v3 {
	background-color: #f2f2f2;
	margin-top: var(--page-margin-top);
	padding-bottom: 90px !important;

	.container {
		@media (min-width: 1500px) {
			max-width: 1240px !important;
		}

	}

	.heading2 {
		font-size: 32px;
		margin-bottom: 15px;
	}

	.list-li {
		li {
			margin-bottom: 30px;
		}
	}

	.inner-banner {
		background-image: url(RESOURCE/img/search-banner.jpg);

		/*	&.ferienunterkunfte-banner {
			background-image: url(RESOURCE/img/ferienunterkunfte-banner.jpg);
		}

		&.loog {
			background-image: url(RESOURCE/img/Loog-banner.jpg);
			background-position: center 70%;
		}

		&.billstrabe {
			background-image: url(RESOURCE/img/billstrabe-banner.jpg);
		}

		&.siedlung {
			background-image: url(RESOURCE/img/siedlung-banner.jpg);
		}

		&.zentrum {
			background-image: url(RESOURCE/img/zentrum-banner.jpg);
		}

		&.ostdorf {
			background-image: url(RESOURCE/img/ostdorf-banner.jpg);
		}

		&.ferienhauser {
			background-image: url(RESOURCE/img/ferienhauser-banner.jpg);
		}

		&.personen {
			background-image: url(RESOURCE/img/personen-banner.jpg);
		}

		&.sauna {
			background-image: url(RESOURCE/img/sauna-banner.jpg);
		}

		&.mit-hund {
			background-image: url(RESOURCE/img/mit-hund-banner.jpg);
		}

		&.mit-kamin {
			background-image: url(RESOURCE/img/mit-kamin-banner.jpg);
		}

		&.workation {
			background-image: url(RESOURCE/img/banner-workation.jpg);
		}

		&.mit-meerblick {
			background-image: url(RESOURCE/img/banner-meerblick.jpg);
		}

		&.langzeit-miete {
			background-image: url(RESOURCE/img/langzeit-miete-banner.jpg);
		}*/

	}

	@media (max-width: 992px) {
		margin-top: 60px;
	}


	.results {
		display: block;
		grid-template-columns: 1fr;
		grid-row-gap: 30px;
		margin-top: 15px;

		@media (min-width:1200px) {
			grid-template-columns: 1fr 1fr;
			column-gap: 30px;
		}

		/*	@media (max-width:992px) {
			grid-template-columns: 1fr 1fr;
			column-gap: 30px;
		} */

		@media (max-width:992px) {
			grid-template-columns: 1fr;
		}

	}

	.more-btn-panel {
		margin-top: 30px;
	}

	.container,
	.container-xl {
		@media (min-width:1200px) {
			/*max-width: 1370px; */
		}

		@media (min-width:1500px) {
			max-width: 1370px;
		}
	}

	.seo-main-wraper {
		.img-auto {
			width: 100%;
		}
	}

}

.my-search-token-v2 {
	margin: 2px;
	padding: 2px 6px;
	display: inline-block;
	border: 1px solid var(--color-grey-normal);
	border-radius: 4px;
	background-color: var(--color-grey-light);
	font-size: 14px;
	cursor: pointer;
	position: relative;
	transition: all 0.5s ease;
	margin-right: 8px;

	.close {
		transition: all 0.5s ease;
		position: absolute;
		height: 13px;
		width: 13px;
		right: -7px;
		top: -7px;
		font-size: 14px;
		text-align: center;
		background-color: var(--color-primary);
		color: #fff;
		border-radius: 50%;
		font-weight: 400;
		line-height: 1px;
		padding-bottom: 2px;
		opacity: 0;
	}

	&:hover {
		border: 1px solid var(--color-primary);
		background-color: var(--color-white);

		.close {
			opacity: 1;
		}
	}
}