.search-form-area {
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media (max-width: 992px) {
		flex-wrap: wrap;
	}

	@media (min-width:1141px) {
		padding-left: 15px;
	}

	.form-flex {
		padding: 15px 8px 5px;

		@media (max-width:1140px) {
			width: 42%;
		}

		@media (max-width:992px) {
			width: 100%;
			padding: 10px 0;
		}

		@media (max-width:640px) {
			padding: 5px 0;
		}

		&:first-child {
			border-left: none;
		}

		.form-bx-area {
			position: relative;
			/*padding-right: 85px;*/
			padding-right: 125px;
			padding-left: 2px;
			border-bottom: 2px solid var(--grey);

			&.pinarea {
				@media (min-width:1240px) {
					padding-right: 55px;
					min-width: 330px;
				}
			}

			@media (max-width:1340px) {
				padding-right: 55px;
			}

			#datepicker-trigger1 {
				&.form-control {
					@media (max-width: 767px) {
						padding-right: 40px;
					}
				}
			}

			button.form-control {
				min-width: 230px;

				@media (max-width:1340px) {
					font-size: 18px;
				}
			}


		}

		.fa,
		.far,
		.fas {
			position: absolute;
			right: 2px;
			top: 44px;
			color: var(--black);
			z-index: 2;
			font-size: 20px;
			pointer-events: none;

			@media (max-width:1340px) {
				top: 28px;
			}

			@media (max-width:1140px) {
				top: 13px;

			}

			&.shadow {
				font-size: 65px;
				top: 0px;
				color: var(--grey);
				z-index: 1;
				right: 14px;

				@media (max-width:1340px) {
					font-size: 48px;
				}

				@media (max-width:1140px) {
					font-size: 37px;
					top: -4px;
					/*opacity: .5;*/
				}

				@media (max-width:640px) {
					font-size: 28px;
					top: 5px;
				}

			}

			&.fa-map-marker-alt {
				right: 8px;

				@media (max-width:1140px) {
					right: 0;
				}

				&.shadow {
					@media (max-width:1140px) {
						right: 9px !important;
					}
				}
			}
		}


		.form-control {
			background-color: var(--white);
			border: none;
			border-radius: 0;
			width: 100%;
			padding: 28px 10px 15px;
			height: inherit;
			box-shadow: inherit;
			font-family: var(--dfont2);
			font-size: 20px;
			text-align: left;


			@media (max-width:1340px) {
				font-size: 18px;
				padding-top: 20px;
				padding-bottom: 20px;
			}

			@media (max-width:1140px) {
				font-size: 16px !important;
				padding: 10px 5px;
			}
		}


	}

	.form-btn {

		@media (max-width:992px) {
			width: 100%;
			text-align: center;
		}

		.btn {
			align-items: center;
			display: flex;
			justify-content: center;
			font-size: 30px;
			border-radius: 0 8px 8px 0;
			font-family: var(--dfont2);
			font-weight: bold;
			height: 120px;
			position: relative;
			margin-left: 15px;

			@media (max-width:1340px) {
				font-size: 24px;
				height: 100px;

			}


			@media (max-width:1140px) {
				font-size: 16px;
				border-radius: 4px;
				position: relative;
				height: inherit;
			}

			i {
				margin-left: 8px;
				color: rgba(255, 255, 255, .25);
				font-size: 30px;
				margin-right: 12px;

				@media (max-width:1140px) {
					font-size: 16px;
					margin-left: 0;
				}
			}


		}
	}
}