.contact-page-layout-v1 {
	margin-top: var(--page-margin-top);

	@media (max-width: 992px) {
		margin-top: 60px;
	}

	.inner-banner {
		background-image: url(RESOURCE/img/banner-kontakt.jpg);
	}

}