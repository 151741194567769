.unit-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.unit-title-v1 {
		@media(max-width:768px) {
			padding-top: 24px;
		}

		h1 {
			position: relative;
			display: inline-block;
			/* background-image: url(RESOURCE/img/icon-logo-bg.svg);
			background-position: right;
			background-size: 31px;
			background-repeat: no-repeat; */
			padding-right: 0 !important;
			text-align: center;
			max-width: 670px;

			&:after {
				margin-left: 30px !important;
				display: none;
			}
		}
	}

	.left-col {
		padding: 0px 15px;

		@media(max-width: 1200px) {
			width: 100% !important;
		}

		@media(min-width: 1201px) {
			width: 68% !important;
		}


		@media(min-width: 1500px) {
			width: 70% !important;
		}
	}

	.right-col {

		padding: 0px 15px;

		@media(max-width: 1200px) {
			display: none;
		}

		@media(min-width: 1201px) {
			width: 32% !important;
		}


		@media(min-width: 1500px) {
			width: 30% !important;
		}
	}


	.booking-hint {
		font-size: var(--font-size-md);
		margin-top: -20px;
		padding-bottom: 10px;
	}

	.unit-book-now-v3 {
		@media(min-width:1200px) {
			background-color: var(--color-light-green);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			display: none;
			margin-bottom: 57px;
		}

		&.is-visible {
			@media(min-width:992px) {
				display: block;
			}
		}
	}

	.unit-book-now-v4 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			margin-bottom: 57px;
		}

	}

	.btn-black {
		background-color: var(--color-white);
		border-color: var(--color-black);
		color: var(--color-black);
		font-size: var(--font-size-sm);
		padding: 6px 12px;

		i {
			margin-right: 3px;
		}

		&:hover {
			background-color: var(--btn-primary-bg-color-hover);
			color: var(--color-black);
			border-color: var(--btn-primary-border-color-hover);
			color: var(--btn-primary-font-color-hover);
		}

		&:focus {
			background-color: var(--color-white);
			border-color: var(--color-black);
			color: var(--color-black);
		}
	}

	.header-buttons {
		position: absolute;
		top: 10px;
		left: 25px;
		z-index: 3;



		@media (max-width:767px) {
			top: 15px;
			left: 30px;
			z-index: 3;
		}

		@media (min-width:577px) {

			.fa,
			.fa-light,
			.far,
			.fas {
				padding-right: 5px;
			}
		}
	}

}