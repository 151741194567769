.search-result-header-v1 {

	border-radius: 3px;
	padding: 20px 0px;
	width: 100%;

	.sort-row {
		display: flex;
		align-items: center;
		justify-content: space-between;


		.h2 {
			font-weight: 400;
		}

		@media (max-width:767px) {
			flex-wrap: wrap;
		}

		.sort-rest {
			.h2 {
				position: relative;
				margin-bottom: 40px;
				/* background-image: url(RESOURCE/img/icon-logo-bg.svg);
				background-position: 95%;
				background-size: 31px;
				background-repeat: no-repeat; */
				margin-bottom: 40px !important;
				padding-right: 90px;
				display: flex;
				align-items: center;

				&:before {
					width: 120px;
					height: 1px;
					border-radius: 5px;
					content: "";
					display: inline-block;
					background-color: var(--heading1-border-color);
					position: absolute;
					left: 0;
					bottom: 0;
				}

				&:after {
					background-image: url(RESOURCE/img/upstay-icon-1.svg);
					content: " ";
					min-width: 31px;
					height: 50px;
					background-size: 31px;
					background-repeat: no-repeat;
					margin-left: 0;
					background-position: center;
					margin-left: 30px;
				}

				@media (max-width:1024px) {
					background-size: 26px;
				}

				@media (max-width: 767px) {
					padding-right: 0;
				}
			}

			@media (max-width:767px) {
				margin-bottom: 30px;
			}
		}

		.sort-by {

			display: flex;
			align-items: center;



			>a.btn {
				position: relative;
				top: -12px;
				margin-right: 8px;
				color: var(--color-white);
			}

			a {
				color: var(--color-black);
			}

			.list-group-item {
				padding: 10px 15px;
			}

			>.box {
				border: none !important;

				.arrow {
					z-index: 2;
				}

				>.body {
					padding: 0;

					.list-group {
						margin-bottom: 0;
					}
				}
			}

			strong {
				width: 45px;
				height: 34px;
				box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 10px 0px;
				text-align: center;
				line-height: 35px;
				font-size: 18px;
				border-radius: 3px;
				border: 1px solid rgba(125, 125, 125, .2);
				margin: -26px -8px 0 0;
				cursor: pointer;

				.fa-sort-amount-down {
					color: #000;
				}
			}

			.sort-item {
				color: var(--color-grey-dark);
				font-size: 12px;
				margin: 5px 0;
				background-color: var(--color-white);
				padding: 10px 12px;
				border: 1px solid #dcdcdc;
				margin-left: -1px;
				display: inline-block;
			}

		}
	}
}