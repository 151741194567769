.contact-form-v1 {
	padding-bottom: 62px !important;

	.checkbox label {
		vertical-align: inherit;
	}

	.red {
		color: var(--color-red);
	}

	.flex-full-height {
		display: flex;

		.col-lg-4 {
			height: 100%;
		}
	}
}