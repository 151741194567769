.about-us-v1 {
	margin-top: var(--page-margin-top);
	padding-bottom: 50px;

	.inner-banner {
		&.newsletter {
			background-image: url(RESOURCE/img/inner-banner-newsletter-new.jpg);
		}
	}

	.about-box {
		/*background-color: var(--color-grey-light);*/

		.row {
			display: flex;
			align-items: center;

			@media (min-width:993px) {
				flex-direction: row-reverse;
			}

			.about-bl {
				padding: 40px;

				@media (max-width:992px) {
					padding: 20px 10px;
				}
			}

			.about-img-bl {
				padding-top: 50% !important;
				position: relative;
				overflow: hidden;

				@media (max-width:992px) {
					padding-left: 15px !important;
					padding-right: 15px !important;
				}

				@media (max-width:450px) {
					padding-top: 70% !important;
				}

				img {
					position: absolute;
					top: 0;
					width: 100%;
					left: 0;
					height: 100%;

					@media (max-width:992px) {
						/*	top: -50%;*/
					}

					@media (max-width:450px) {
						/*	top: -30%;*/
					}

				}
			}
		}
	}

	.inner-banner {
		background-image: url(RESOURCE/img/banner-juist.jpg);
	}

	@media (max-width: 992px) {
		margin-top: 60px;
	}

	.team-members {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(min(var(--about-us-grid-width), 100%), 1fr));
		grid-gap: 1em;
	}

	.member-info {
		text-align: center;
		background-color: var(--color-white);

		.name {
			font-weight: 600;
		}

		.resort {
			font-size: var(--font-size-md);
			color: var(--color-grey-dark);
			margin-bottom: 10px;
		}

		.phone {
			font-size: var(--font-size-md);
		}

		.email {
			font-size: var(--font-size-md);
		}

	}

	.about-logo {
		max-width: 100%;
	}


}