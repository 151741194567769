.footer-v1 {
	position: relative;
	padding-top: 20px;
	margin-top: 50px;

	@media (max-width:750px) {
		padding-top: 70px;
	}

	.ft-logo {
		img {
			max-width: 100%;
		}
	}

	.newsletter-post-bl {
		top: -20px;
		position: absolute;
		z-index: 5;
		width: 100%;
		padding: 0 20px;



		.form-control {
			font-size: 16px;
			height: inherit;
			margin-right: 2px;
			padding: 10px 20px;

			@media (max-width:767px) {
				padding: 10px 0;
				font-size: 13px;
			}
		}

		.text-muted {
			font-weight: bold;
			color: var(--color-white) !important;
		}

		.newslet-bl {
			background-color: var(--color-secondary);
			padding: 20px;
			border-radius: 10px;
			max-width: 1350px;
			margin: 0 auto;
			text-align: center;
			color: var(--color-white);

			@media (max-width:767px) {
				font-size: 12px;
			}

			p:last-child {
				margin-bottom: 0;
			}
		}

		.widt-cont-bl {
			max-width: 490px;
			margin: 0 auto;
			border: 1px solid var(--color-white);
			border-radius: 30px;
			padding: 0 !important;
			padding-left: 19px !important;
			background-color: var(--color-white);



			.btn-primary {
				padding: 12px 40px;
				border-radius: 30px;

				@media (max-width:767px) {
					width: 44px;
					padding: 12px 10px;

					span {
						display: none;
					}

					&:before {
						content: "\f054";
						font-style: normal;
						font-variant: normal;
						text-rendering: auto;
						font-weight: 900;
						-webkit-font-smoothing: antialiased;
						display: inline-block;
						font-family: "Font Awesome 5 Free";
						font-size: 24px;
						position: absolute;
						top: 0;
						left: 50%;
						transform: translate(-50%, 0);
					}
				}
			}


		}
	}

	.main-footer {
		padding-top: 80px;
		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		border-top: 1px solid var(--footer-border-top-color);

		.text-muted {
			color: var(--color-grey-normal) !important;

		}

		.icon-white-li {
			display: flex;
			flex-wrap: wrap;

			li {
				list-style: none;
				margin-bottom: 22px;
				position: relative;
				padding-left: 45px;

				color: var(--color-black);
				display: block;
				width: 100%;

				i {
					font-size: 24px;
					position: absolute;
					left: 0;
					top: 2px;
					color: var(--color-secondary);
				}
			}

		}

		.logo-section {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			@media (min-width:992px) {
				-webkit-box-flex: 0;
				flex: 0 0 18.666667%;
				max-width: 18.666667%;
			}
		}

		.first-col {
			@media (min-width:992px) {
				flex: 0 0 21%;
				max-width: 21%;
			}
		}

		padding-left: 5vw;
		padding-right: 5vw;

		.headline {
			font-size: 20px;
			color: var(--color-black);
			text-transform: uppercase;
			font-family: var(--title-font-family-main);
		}


		.logo {
			max-width: 250px;
			height: auto;
			margin-top: -40px;
			padding-bottom: 20px;
			width: 100%;
		}

		.openings {

			div {
				padding-top: 10px;
			}
		}

		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {
				margin-right: 15px;

				a {
					color: var(--footer-font-color);
				}

				.fa {
					font-size: 24px;
					color: var(--footer-font-color);

				}
			}

		}




		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #ffc371;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}

		.paymentmethods {
			width: 100%;
			text-align: right;
			margin-top: -20px;
			padding-right: 15px;
			position: relative;
			top: 25px;

			.fa {
				color: var(--color-black);
				font-size: 2.3rem;
				padding-left: 10px;

			}
		}
	}

	.copyright {
		background-color: var(--color-secondary);
		color: var(--color-white);

		.flex-row {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		a {
			color: var(--footer-copyright-font-color);
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		.v-office-logo {
			float: left;
			align-items: center;
			display: flex;

			>p {
				margin-bottom: 0;
			}

			img {
				width: 50px;
				margin-right: 20px;
			}
		}
	}

}