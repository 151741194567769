.searchbar-v1 {
	position: absolute;
	bottom: 180px;
	width: 100%;

	@media (max-width: 1100px) {
		bottom: 0;

		background-color: #fff;
		padding: 25px;
		position: relative;
	}

	@media (max-width: 576px) {
		padding: 0px 10px;
		margin-bottom: 30px;
	}



	.container {
		@media (max-width:1100px) {
			padding: 10px 20px;
			border-radius: 8px;
			border: 1px solid var(--color-grey-light);
		}
	}

	.title {
		color: #fff;
		background-color: var(--color-secondary);
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		padding: 10px 20px;
		opacity: 0.8;

	}

	.searchbar {
		display: inline-block;
		text-align: left;
		background-color: var(--white);
		border-radius: 8px;

		@media (max-width:1100px) {
			display: block;
		}
	}

	.search-form-area {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media (max-width: 1100px) {
			flex-wrap: wrap;
		}

		@media (min-width:1141px) {
			padding-left: 15px;
		}





		.form-flex {
			padding: 15px 8px 5px;

			@media (max-width:1140px) {
				width: 42%;
			}

			@media (max-width:1100px) {
				width: 100%;
				padding: 10px 0;
			}

			@media (max-width:640px) {
				padding: 5px 0;
			}

			&:first-child {
				border-left: none;
			}

			.form-bx-area {
				position: relative;
				/*padding-right: 85px;*/
				padding-right: 125px;
				padding-left: 2px;
				border-bottom: 2px solid var(--color-grey-light);

				&.pinarea {
					@media (min-width:1240px) {
						padding-right: 55px;
						min-width: 330px;
					}
				}

				@media (max-width:1340px) {
					padding-right: 55px;
				}

				#datepicker-trigger1 {
					&.form-control {
						@media (max-width: 767px) {
							padding-right: 40px;
						}
					}
				}

				button.form-control {
					min-width: 230px;

					@media (max-width:1340px) {
						font-size: 18px;
					}
				}


			}

			.fa,
			.far,
			.fas {
				position: absolute;
				right: 2px;
				top: 44px;
				color: var(--black);
				z-index: 2;
				font-size: 20px;
				pointer-events: none;

				@media (max-width:1340px) {
					top: 28px;
				}

				@media (max-width:1140px) {
					top: 13px;

				}

				&.shadow {
					font-size: 65px;
					top: 0px;
					color: var(--color-grey-light);
					z-index: 1;
					right: 14px;
					box-shadow: inherit !important;

					@media (max-width:1340px) {
						font-size: 48px;
					}

					@media (max-width:1140px) {
						font-size: 37px;
						top: -4px;
						/*opacity: .5;*/
					}

					@media (max-width:640px) {
						font-size: 28px;
						top: 5px;
					}

				}

				&.fa-map-marker-alt {
					right: 8px;

					@media (max-width:1140px) {
						right: 0;
					}

					&.shadow {
						@media (max-width:1140px) {
							right: 9px !important;
						}
					}
				}
			}


			.form-control {
				background-color: var(--white);
				border: none;
				border-radius: 0;
				width: 100%;
				padding: 28px 10px 15px;
				height: inherit;
				box-shadow: inherit;
				font-family: var(--dfont2);
				font-size: 20px;
				text-align: left;


				@media (max-width:1340px) {
					font-size: 18px;
					padding-top: 20px;
					padding-bottom: 20px;
				}

				@media (max-width:1140px) {
					font-size: 16px !important;
					padding: 10px 5px;
				}
			}


		}

		.form-btn {

			@media (max-width:1100px) {
				width: 100%;
				text-align: center;
			}

			.searchbox {
				padding: 0;
				position: relative;
				right: -1px;


				@media (max-width:1140px) {

					display: inline-block;
					right: 0;
				}

				@media (max-width: 1100px) {
					padding-top: 10px;
				}
			}

			.btn {
				align-items: center;
				display: flex;
				justify-content: center;
				font-size: 30px;
				border-radius: 0 8px 8px 0;
				font-family: var(--dfont2);
				font-weight: bold;
				height: 120px;
				position: relative;
				margin-left: 15px;

				@media (max-width:1340px) {
					font-size: 24px;
					height: 100px;

				}


				@media (max-width:1140px) {
					font-size: 16px;
					border-radius: 4px;
					position: relative;
					height: inherit;
				}

				i {
					margin-left: 8px;
					color: rgba(255, 255, 255, .25);
					font-size: 30px;
					margin-right: 12px;

					@media (max-width:1140px) {
						font-size: 16px;
						margin-left: 0;
					}
				}


			}
		}
	}

	.form-control:disabled,
	.form-control[readonly] {
		background-color: var(--color-white);
	}

	.list-group-item {
		color: var(--font-color-main);
		text-decoration: none;

		&:hover {
			color: var(--font-color-main);
			text-decoration: none;
		}

		&.active {
			background-color: transparent;
			border: 1px solid var(--color-primary);
		}
	}
}