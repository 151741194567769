.parallax-section {
	background-image: url(RESOURCE/img/parallax-image.jpg);
	position: relative;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-attachment: fixed;
	margin-top: 0;

	@media (max-width:640px) {
		margin-top: 0px;
	}

	&:before {
		background-color: var(--white);
		height: 130px;
		width: 100%;
		display: block;
		position: absolute;
		left: 0;
		top: -1px;
		content: "";

		@media (max-width:992px) {
			height: 60px;
		}
	}

	&:after {
		background-color: var(--white);
		height: 130px;
		width: 100%;
		display: block;
		position: absolute;
		left: 0;
		bottom: -1px;
		content: "";

		@media (max-width:992px) {
			height: 60px;
		}
	}


	.parallax-content {
		padding: 80px;
		position: relative;
		z-index: 1;
		background-color: #afb8b0;
		color: #fff;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		max-width: 880px;

		@media (min-width:1200px) {
			left: 8%;
		}




		.section-title {

			>h1 {
				font-size: 60px;
				color: #fff;
				margin-bottom: 40px;
				text-align: left;
				display: inline-flex !important;

				&:before {
					background-color: #fff;
				}

				@media (max-width:640px) {
					font-size: 40px;
				}
			}



			@media (max-width:992px) {
				margin-bottom: 20px;
			}
		}

		@media (max-width:992px) {
			min-height: 510px;
			padding: 20px;
		}

		@media (max-width:768px) {
			min-height: auto;
		}

		p {
			font-size: 22px;
			color: #fff;
			margin-bottom: 30px;

			@media (max-width:992px) {
				font-size: 14px;
				margin-bottom: 20px;
			}
		}

		a {
			color: var(--text-dark);

			&:hover {
				color: var(--secondary);
			}
		}
	}
}