.gallery-tag {
	.btn-tag {
		position: absolute;
		bottom: 15px;
		right: 25px;
		pointer-events: none;
	}
}

.unit-image-gallery-v3 {


	.visible-print {
		display: none !important;
	}

	.unitGallery {
		div {
			display: none;
			padding: 1px;
			height: 190px;

			.gallery_block {
				height: 190px !important;
				width: 100% !important;

				@media (max-width: 767px) {
					height: 100% !important;
					padding: 0;
				}

				a {
					width: 100%;
					padding: 0;
					height: 100%;
				}
			}




			span {
				display: none;
			}


			@media (max-width: 767px) {
				padding: 5px;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			&:nth-child(1) {
				width: 50%;
				float: left;
				display: block;
				height: 380px;
				overflow: hidden;

				.gallery_block {
					height: 380px !important;

					@media (max-width: 767px) {
						height: 290px !important;
					}
				}


				@media (max-width: 767px) {
					width: 100%;
					height: 300px;
				}
			}

			&:nth-child(2) {

				.gallery_block_main,
				.video-bl,
				.video-box {
					height: 190px !important;
					width: 100% !important;

					@media (max-width: 767px) {
						height: 100% !important;
						padding: 0;
					}
				}

				.video-bl,
				.video-box {
					height: 100% !important;

				}

				.video-box {
					padding: 0;
				}

				.gallery_block_main {
					a {
						display: none;
					}

				}

				.gallery_block_main {
					iframe {
						height: 100%;
						width: 100%;
					}
				}
			}

			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(5),
			&:nth-child(4) {
				width: 25%;
				float: left;
				display: block;

				@media (max-width: 767px) {
					width: 50%;
					height: 140px;
				}


			}




			&:nth-child(6),
			&:nth-child(7),
			&:nth-child(8) {
				display: none;
				width: 20%;
				float: left;

				@media (max-width: 767px) {
					width: 50%;
					height: 140px;
				}
			}

			&:nth-child(8) {
				position: relative;

				&:before {
					content: "+ mehr";
					position: absolute;
					left: 0;
					right: 0;
					top: 50%;
					bottom: 0;
					text-align: center;
					width: 100%;
					color: #fff;
					font-weight: 600;
					font-size: var(--font-size-md);
					margin-top: -10px;
					z-index: 1;
					padding: 0 10px;
				}

				&:after {
					content: "";
					position: absolute;
					left: 5px;
					right: 5px;
					top: 5px;
					bottom: 5px;
					background: rgba(0, 0, 0, .5);
					z-index: 0;

					@media (max-width: 767px) {
						top: 5px;
						bottom: 5px;
					}
				}

			}
		}
	}

	.unitGallery {
		a {
			display: none;
			padding: 1px;
			height: 190px;


			@media (max-width: 767px) {
				padding: 5px;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			&:nth-child(1) {
				width: 50%;
				float: left;
				display: block;
				height: 380px;
				overflow: hidden;

				@media (max-width: 767px) {
					width: 100%;
					height: 300px;
				}
			}

			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(5),
			&:nth-child(4) {
				width: 25%;
				float: left;
				display: block;

				@media (max-width: 767px) {
					width: 50%;
					height: 140px;
				}
			}




			&:nth-child(6),
			&:nth-child(7),
			&:nth-child(8) {
				display: none;
				width: 20%;
				float: left;

				@media (max-width: 767px) {
					width: 50%;
					height: 140px;
				}
			}

			&:nth-child(8) {
				position: relative;

				&:before {
					content: "+ mehr";
					position: absolute;
					left: 0;
					right: 0;
					top: 50%;
					bottom: 0;
					text-align: center;
					width: 100%;
					color: #fff;
					font-weight: 600;
					font-size: var(--font-size-md);
					margin-top: -10px;
					z-index: 1;
					padding: 0 10px;
				}

				&:after {
					content: "";
					position: absolute;
					left: 5px;
					right: 5px;
					top: 5px;
					bottom: 5px;
					background: rgba(0, 0, 0, .5);
					z-index: 0;

					@media (max-width: 767px) {
						top: 5px;
						bottom: 5px;
					}
				}

			}
		}
	}

}